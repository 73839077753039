import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';

import images from 'assets/images';
import { UserComponent } from 'components/UserComponent';
import { TipMe } from 'components/PaymentProvider'


const logoSymbolStyle = {
  width: 'auto',
  height: '50px',
  cursor: 'pointer',
  marginRight: '05px',
};

const logoTextStyle = {
  width: '100px',
  height: 'auto',
  cursor: 'pointer',
  marginRight: '10px',
};

function NavBar() {
  /// handle drawer and menu items
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  // const scrollToSection = (sectionId: string) => {
  //   const sectionElement = document.getElementById(sectionId);
  //   const offset = 128;
  //   if (sectionElement) {
  //     const targetScroll = sectionElement.offsetTop - offset;
  //     sectionElement.scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo({
  //       top: targetScroll,
  //       behavior: 'smooth',
  //     });
  //     setOpen(false);
  //   }
  // };


  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'rgb(230, 240, 255)',
          border: '0.5px solid rgba(0, 0, 0, 0.1)', 
          // height: 60,
          // mt: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
              height: '80px'
            }}
          >
            <img
              src={images.logo.symbol}
              style={logoSymbolStyle}
              alt="Symbol logo"
            />
            <img
              src={images.logo.text}
              style={logoTextStyle}
              alt="Text logo"
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <NavBarItemHorizontal path="/about">About</NavBarItemHorizontal>
              <NavBarItemHorizontal path="/cloud">Cloud</NavBarItemHorizontal>
              <NavBarItemHorizontal path="/robotics">Robotics</NavBarItemHorizontal>
              <NavBarItemHorizontal path="/intelligence">Intelligence</NavBarItemHorizontal>
            </Box>
          </Box>
            
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 3,
              alignItems: 'center',
            }}
          > 
            <TipMe/>
            <UserComponent/>
          </Box>
          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                  marginTop: 10,
                }}
              >
                <NavBarItemVertical path="/about">About</NavBarItemVertical>
                <NavBarItemVertical path="/cloud">Cloud</NavBarItemVertical>
                <NavBarItemVertical path="/robotics">Robotics</NavBarItemVertical>
                <NavBarItemVertical path="/intelligence">Intelligence</NavBarItemVertical>
                <Divider />
                <MenuItem><TipMe/></MenuItem>
                <MenuItem><UserComponent/></MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

interface NavBarItemProps {
  path: string;
  children: React.ReactNode;
}

function NavBarItemHorizontal(props: NavBarItemProps) {
  const navigate = useNavigate();
  const pathname: string = useLocation().pathname;
  var textStyle = {};
  var itemStyle = {};
  if (pathname === props.path ) {
    textStyle = {fontWeight: 'bold' }
    itemStyle = {backgroundColor: 'rgba(0, 0, 0, 0.075)'};
  }

  return (
    <MenuItem
    onClick={() => navigate(props.path)}
    sx={{ py: '6px', px: '12px' }}
    style={itemStyle}
  >
    <Typography 
      style={textStyle}
      variant="body1" color="text.primary"
    >
      {props.children}
    </Typography>
  </MenuItem>
  );
}

function NavBarItemVertical(props: NavBarItemProps) {
  const navigate = useNavigate();
  const pathname: string = useLocation().pathname;
  var textStyle = {};
  if (pathname === props.path ) {
    textStyle = {fontWeight: 'bold' }
  }

  return (
    <MenuItem
      onClick={() => navigate(props.path)}
      style={textStyle}
    >
      {props.children}
    </MenuItem>
  );
}

export default NavBar;
