import React, {useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import MarkDown from 'markdown-to-jsx';

import { useAuth } from 'components/AuthProvider';

interface MarkDownProps {
  id?: string;
  title: string;
  content: string;
}

export function MarkDownBlock(props: MarkDownProps) {
  const { id, title, content } = props;
  return (
    <Grid
      xs={12}
      md={8}
      id={id}
      item
      sx={{
        '& .markdown': {
          py: 3,
        },
        marginBottom: 4,
        width: '100%',
      }}
    >
      <Typography 
        variant="h4" 
        gutterBottom 
        // style={{width: '100%', wordBreak: 'break-all'}}
      >
        {title}
      </Typography>
      {title && <Divider />}
      <MarkDown>{content}</MarkDown>
    </Grid>
  );
}


/// instantiate api call
const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_ROOT,
});

interface ProtectedProps {
  id?: string;
}

export function ProtectedBlock(props: ProtectedProps) {
  let { id, } = props;

  /// protected content
  const {user, logout, getAccessTokenSilently} = useAuth();
  const protectedContentQuery = useQuery({
    queryKey: ['content', id],
    queryFn: async () => {
      if (!user) return null;

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
          scope: 'user:full-access',
        }
      });
      if (!accessToken) throw new Error('No access token');

      const response = await coreApi.get(
        '/content',
        {          
          params: { contentId: id!},
          headers: {Authorization: `Bearer ${accessToken}`}
        }       
      );
      return response.data;
    },
    retry: 3,
  })

  useEffect(() => {
    if (protectedContentQuery.isError) {
      console.error('Error fetching protected content:', protectedContentQuery.error);
      alert('Failed to fetch protected content. Please log back in.')
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    }
  }, [protectedContentQuery.isError, protectedContentQuery.error]);

  /// no render
  if (!user || protectedContentQuery.isError) return <></>

  /// loading
  else if (protectedContentQuery.isLoading) {
    return (
      <Grid
        xs={12} md={8} id={id} item
        sx={{
          '& .markdown': {
            py: 3,
          },
          marginBottom: 4,
          width: '100%',
        }}
      >
        <LinearProgress />
      </Grid>
    );  
  }

  /// markdown rendering
  return (
    <Grid
      xs={12} md={8} id={id} item
      sx={{
        '& .markdown': {
          py: 3,
        },
        marginBottom: 4,
        width: '100%',
      }}
    >
      <Typography variant="h4" gutterBottom>
        {protectedContentQuery.data.title}
      </Typography>
      {protectedContentQuery.data.title && <Divider />}
      <MarkDown>
        {protectedContentQuery.data.content}
      </MarkDown>
    </Grid>
  );    
}

const post = `

`