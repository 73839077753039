import {GradientBackground} from 'components/GradientBackground';
import {MarkDownBlock} from 'components/TextRender';
import ImageComponent from 'components/ImageComponent';

const drawerWidth = 240;

export function Page() {
  return (
    <GradientBackground>

      <MarkDownBlock title="About KeyPointVision" content={KeypointvisionAbout}/>
      
      <MarkDownBlock title="About Me" content={""}/>
      <ImageComponent  /// TODO: reconsider file location - images text etc
        src={`${process.env.PUBLIC_URL}/assets/portrait.png`} 
        alt="" 
        aspectRatio={845/1026}
        style={{ 
          width: '100%', 
          maxWidth: '400px',
          marginBottom: '40px',
          alignSelf: 'flex-start',
        }} 
      /> 
      <MarkDownBlock title="" content={AboutMe}/>
      
      <MarkDownBlock title="Data and Security" content={DataNSecurity} />
    </GradientBackground>
  );
}


const KeypointvisionAbout = `
Welcome to KeyPointVision! This website serves as both a showcase of my capabilities and a playground for my exploration. My portfolio includes projects in Machine Learning, Robotics, and Cloud Computing, reflecting my accumulated experience over the years and my interests going forward. Some contents highlights my skills while others are thoughts and ideas for the future. 

Unlike GitHub or LinkedIn, this self-hosted website offers the flexibility to present a wide range of content without limitations imposed by popular platforms or risk of going off topic. It also supports extensive user interactions, allowing you to experience what is possible first-hand.

The name “KeyPointVision” originates from my first significant software project involving human pose estimation. In this project, anatomical joint positions, referred to as key points, were extracted from images. The key points predicted from the computer vision algorithm were distilled from seemingly random noise, a recurring theme in many of my projects. Thus, the name KeyPointVision was born.  

Thank you for visiting, and I hope you enjoy exploring my work!
`

const AboutMe = `
I am Tyler, a software engineer based in Northern California, currently working at LGC. LGC is a multinational organization that conducts science for a safer world by providing more accurate and affordable diagnostics and testing products/services. My experience is mostly concentrated at the California site which specializes in manufacturing oligonucleotides and their precursors/derivatives within the supply chain. Oligonucleotides are short DNA/RNA sequences which you have likely encountered in PCR COVID tests and next-generation sequencing diagnostic screenings. 

My role involves organizing and utilizing manufacturing data. I have deployed quality system and data warehouse capabilities to support oligonucleotide production. My responsibilities also include organizing data sources, connecting data pipelines, and deriving actionable insights. As the infrastructure matured, I built machine learning tools that leverage deep learning transformer architecture to further assist in oligonucleotide manufacturing.

My three most important values are learning, progress, and collaboration. My long-term mission is to expand the horizon of knowledge and insights by building more intelligent systems. I display high C followed by D tendencies in the DISC index. I have a degree in Biochemistry but a passion for machine learning software. These attributes have driven my efforts to terraform the software infrastructure suitable for machine learning at Biosearch and various exploratory projects outside of work, like building this website. 

Besides software, I have a keen interest in the culinary arts and enjoy savoring delightful meals with family. To maintain a healthy lifestyle, I casually participate in tennis, skiing, and badminton. Fun fact: I have ten years of experience and hold a black belt in Kendo—Japanese fencing, so… expect me to wield a cane with style.
`

const DataNSecurity = `
Note the buttons for user login and payment in the top right corner. KeyPointVision employs Okta to securely manage your authentication on this site. Similar to buymeacoffee.com, this site integrated Stripe to robustly handle the payment process and safeguard your payment data. Additional security measures are detailed in the cloud section.  

The KeyPointVision-managed infrastructure only stores your comments, contribution amounts, and email. Your email and contribution amounts are removed when you delete your account. If no monetary contribution is present, your data will also be removed once the account reaches its retention period. You can view or delete your account by clicking on your icon on the top right after you login.

Thank you for trusting KeyPointVision with your data and for supporting my work!
`
