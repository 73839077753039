import { lazy, Suspense, useEffect  } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, useLocation, Routes, Route} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Box from '@mui/material/Box';

import {Page as About} from 'pages/abouts/Page';
import {Page as Payment } from 'pages/payments/Page';
import { AuthProvider } from 'components/AuthProvider';
import { Loading } from 'components/GradientBackground';
import NavBar from './NavBar';

const defaultTheme = createTheme({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

// const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
// const Cloud = lazy(async () => {
//   await wait(2000);
//   return import('pages/clouds/Page').then((module) => ({ default: module.Page }));
// });
const Cloud = lazy(() => import('pages/clouds/Page').then((module) => ({ default: module.Page })));
const Robotics = lazy(() => import('pages/robotics/Page').then((module) => ({ default: module.Page })));
const Intelligence = lazy(() => import('pages/intelligence/Page').then((module) => ({ default: module.Page })));


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

  
function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Router>
      <AuthProvider>
      <QueryClientProvider client={queryClient}>  

        <NavBar />
        <ScrollToTop />
        <Suspense fallback={<Loading/>}>
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="/about"/>} /> */}
          <Route path="/" element={<About />}/>
          <Route path="/about" element={<About />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/robotics" element={<Robotics />} />
          <Route path="/intelligence" element={<Intelligence />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
        </Suspense>
        {/* <Footer/> */}

      </QueryClientProvider>
      </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
