import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import Footer from 'components/Footer';

export function GradientBackground({children}: {children: React.ReactNode}) {
  const minHeight = '1600px';
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minHeight: minHeight,
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% ' + minHeight,
        backgroundRepeat: 'no-repeat',
      })}
    >
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 14 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      {children}
      <Footer/>
    </Container>
    </Box>
  );
}

export function Loading() {
  return (<GradientBackground>
    <LinearProgress/>
  </GradientBackground>)
}
